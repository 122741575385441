import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import './index.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer'; 


class CreateProfle extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        import('wowjs').then((WOW) => new WOW.WOW({
          boxClass: 'wow',           // animated element css class (default is wow)
          animateClass: 'animated',  // animation css class (default is animated)
          offset: 5,                 // distance to the element when triggering the animation (default is 0)
          mobile: true,              // trigger animations on mobile devices (default is true)
          live: true,                // act on asynchronously loaded content (default is true)
          callback: function (box) {
            // the callback is fired every time an animation is started
            // the argument that is passed in is the DOM node being animated
          },
          scrollContainer: null      // optional scroll container selector, otherwise use window
        }).init());
      };

    render() {
        return (
            <div className="edit-profile">
                <div className="profile-page" style={{ backgroundImage: `url(${require("../../static/images/landing/lottries/editporfile.png")})` }}>
                <Navbar />
                    <section className="profile-edit-sec">
                        <div className="container">
                            <div className="row">
                            <div className="form-block col-lg-6 col-md-12 col-sm-12  wow fadeInRight" data-wow-delay="400ms" data-wow-duration="400ms">
                                <div className="sec-title text-center">
                                    <h2>Create Profile</h2>
                                </div>
                                <div className="form-profile">
                                    <form className="form">
                                        <div className="group-form">
                                            <input type="text" name="name" placeholder="Enter display name" />
                                        </div>
                                        <div className="group-form">
                                            <input type="url" name="url" placeholder="Enter custom URL" />
                                        </div>
                                        <div className="group-form">
                                            <textarea placeholder="Write about yourself"></textarea>
                                        </div>
                                        <div className="group-form">
                                            <input type="url" name="linkTwitter" placeholder="Link your twitter account" />
                                        </div>
                                        <div className="group-form">
                                            <input type="url" name="linkPortfolio" placeholder="Enter portfolio link" />
                                        </div>
                                        <div className="group-form">
                                            <input type="email" name="email" placeholder="Enter Email" />
                                        </div>
                                        <div className="group-form">
                                            <p>Account not verified. <Link to='#'> verify now</Link></p>
                                        </div>
                                        <div className="group-form text-center">
                                            <button className="btn-style-one">Save changes</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="user-area-profile col-lg-6 col-md-12 col-sm-12  wow fadeInLeft" data-wow-delay="400ms" data-wow-duration="400ms">
                                <div className="inner-box">
                                    <div className="user-image-area">
                                        <img src={require("../../static/images/landing/user-img.png")} alt="" />
                                        <Link to='#' className="user-icon"> <i className="icon"><img src={require("../../static/images/landing/user-change.png")} alt="" /></i> </Link>
                                    </div>
                                    <div className="user-info">
                                        <h2>Tocaya</h2>
                                        <h4>@tocaya</h4>
                                        <p>Tocaya decided to launch this 1 of 1 NFT as a way to use their platform to</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </section>
                <Footer />
                </div>
                
            </div>
        );
    }
}

const mapDispatchToProps = {

};

const mapStateToProps = ({ }) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProfle);