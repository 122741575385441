import { PURGE } from "redux-persist";
import { setToken } from '../axios';

var initialState =
{
  isModal: false,
  buyToken: {},
  isLogin: false,
  isConnectedModal: false,
  auth: localStorage.getItem('token'),
  address: localStorage.getItem('publicAddress'),
  UpdatedProfile:[],
  userprofileData:[]
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {

    case 'TOGGLE_POLICY_MODAL':
      localStorage.setItem('cookies', 'false');
      return {
        ...state,
        isPolicyModal: !state.isPolicyModal,
      };

    case 'SET_ADDRESS':
      localStorage.setItem('publicAddress', payload);
      return {
        ...state,
        address: payload,
      };

    case 'LOGIN_TOKEN':
      setToken(payload);
      localStorage.setItem('token', payload);
      return {
        ...state,
        auth: payload,
      };

    case 'IS_LOGGED_IN':
      return {
        ...state,
        isLogin: payload,
      };

    case 'LOGOUT':
      localStorage.removeItem('publicAddress');
      localStorage.removeItem('token');
      return {
        ...state,
        address: '',
        auth: '',
      };

    case 'TOGGLE_CONNECT_MODAL':
      return {
        ...state,
        isConnectedModal: payload,
      };

    case 'SET_PROFILE_UPDATE_FROM_API':
      return {
          ...state,
          UpdatedProfile: payload
      }
    case 'GET_DATA_PROFILE_FROM_API':
      return {
        ...state,
        userprofileData: payload
      }

    default:
      return state;
  }
};
export default Auth;
