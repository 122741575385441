import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { web3 } from '../web3.js';
import { all, takeEvery, call, put } from 'redux-saga/effects';

import { setAddress, loginToken,getDataUserProfileFromApi, setProfileUpdateFromApi } from '../actions/Auth';


function* login({ payload }) {
  const { error, response } = yield call(getCall, `/users/getUserNonce/${payload['publicAddress']}`);
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    try {
      var nonceObj = response['data']['body']['nonceObject'];
      // SIGING THE NONCE
      const signature = yield web3.eth.personal.sign(web3.utils.utf8ToHex(`Login Nonce: ${nonceObj["nonce"]}`), payload['publicAddress'])
      const data = {
        signature,
        publicAddress: payload['publicAddress'],
      }

      const metaMaskResponse = yield call(postCall, { path: `/users/login`, payload: data });
      if (error) EventBus.publish("error", error['response']['data']['message']);
      else if (metaMaskResponse) {
        let { token } = metaMaskResponse['response']['data']['body']
        const decoded = jwt_decode(token, { header: false });
        if (decoded["role"] !== "user") {
          EventBus.publish("error", "Invalid Public Address");
          yield put({ type: "IS_LOGGED_IN", payload: false });
          return;
        }
        yield put(loginToken(token));
        yield put(setAddress(payload['publicAddress']));
        EventBus.publish("success", metaMaskResponse['response']['data']['message'])
      }
    } catch (e) { yield put({ type: "IS_LOGGED_IN", payload: false }); }
  }
  yield put({ type: "IS_LOGGED_IN", payload: false });
};

function* signup({ payload }) {
  const { error, response } = yield call(postCall, { path: '/users/signup', payload });
  if (error) {
    yield put({ type: "IS_LOGGED_IN", payload: false });
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put({
      type: 'LOGIN', payload: {
        publicAddress: payload['publicAddress']
      }
    });
  }
};



function* UpdateUserProfile({payload}) {
  try {
    const { error, response }  = yield call(putCallWithFile, { path: `/users/update`, payload: payload });
      if(response){
        yield put(setProfileUpdateFromApi(response));
        EventBus.publish("success", response['data']['message'])
      }else{
        EventBus.publish("error", error['data']['message'])
      }
  } catch (e) { yield put({ type: "IS_LOGGED_IN", payload: false }); }
}


function* getUserData({ payload }) {
  try {
    const { error, response } = yield call(getCall, `/users/getUser/${payload}`);
      if(response){
        yield put(getDataUserProfileFromApi(response['data']['body']));
        // EventBus.publish("success", response['data']['message'])

                // yield put(loginToken(token));
      }else{
        // EventBus.publish("error", error['data']['message'])
      }
  } catch (e) { yield put({ type: "IS_LOGGED_IN", payload: false }); }
};


function* actionWatcher() {
  yield takeEvery('SIGN_UP', signup);
  yield takeEvery('LOGIN', login);
  yield takeEvery('SET_PROFILE_UPDATE', UpdateUserProfile);
  yield takeEvery('GET_DATA_PROFILE', getUserData);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}


function putCallWithFile({ path, payload }) {
  console.log('headers content type ==========',payload )

        const formData = new FormData();
        formData.append('avatar',payload.file);
        formData.append('bio',payload.bio);
        formData.append('email',payload.email);
        formData.append('name',payload.name);
        formData.append('phone',payload.phone);
        formData.append('twitter',payload.twitter);
        formData.append('publicaddress',payload.publicaddress);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

      return axios
        .put(path, formData, config)
        .then(response => ({ response }))
        .catch(error => {
          if (error.response.status === 401) EventBus.publish("tokenExpired");
          return { error };
      });
}


function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}
