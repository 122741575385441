import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

import './index.css';
import { web3 } from '../../store/web3';
import { message, networkId, explorer } from '../../store/config';

import { login, isLoggedIn, logout, signup, toggleConnectModal, toggleSwitchModal } from "../../store/actions/Auth";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            publicAddress: '',
            isLogout: false,
            isPhysicalAddress: false,
        };
    }

    componentDidMount() {
        let paddress = localStorage.getItem('publicAddress');
        if (paddress) {
            this.setState({ isPhysicalAddress: true })
        }
    };

    componentDidUpdate(prevProps, prevState) {
        let paddress = localStorage.getItem('publicAddress')
        if (prevProps.isLogin !== this.props.isLogin) {
            if (this.props.isLogin === false) {
                if (this.props.address === paddress) {
                    this.setState({ isPhysicalAddress: true })
                }
                this.setState({ isWallet: !this.state.isWallet })
            }
        }
    };

    connectWallet = async () => {
        // if (typeof window.ethereum === 'undefined') {
        //     let { isPolicyModal } = this.props;
        //     if (!isPolicyModal) this.props.toggleConnectModal(true);
        //     return;
        // }
        // web3.eth.net.getId(async (err, netId) => {
        //     if (netId != networkId) {
        //         let { isPolicyModal } = this.props;
        //         if (!isPolicyModal) this.props.toggleSwitchModal(true);
        //         return;
        //     }
        //     let address = (await web3.currentProvider.enable())[0];
        //     this.props.isLoggedIn(true);
        //     this.props.signup({ publicAddress: address });
        // });
    };

    copied = () => EventBus.publish("success", 'Wallet Address Copied');

    logoutWallet = () => {
        this.props.logout();
        this.setState({ isLogout: !this.state.isLogout });
    };

    handleChange = (event) => {
        let { formData } = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    };

    toggleLogoutModal = () => this.setState({ isLogout: !this.state.isLogout });


    render() {
        let { isLogout } = this.state;
        let { address, isLogin } = this.props;
        return (
            <div className="main-header">
                <nav className="navbar navbar-expand-lg">
                    <div className="container ">
                        <Link className="navbar-brand" href="#"><img src={require("../../static/images/landing/logo.png")} alt="" /></Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <i class="fa fa-bars " aria-hidden="true"></i>
                        </button>
                        <div className="collapse navbar-collapse nav-links" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <HashLink className='nav-link' smooth to='/'>Home</HashLink>
                                </li>
                                <li className="nav-item">
                                    <HashLink className='nav-link' smooth to='/#feature'>Features </HashLink>
                                </li>
                                <li className="nav-item">
                                    <HashLink className='nav-link' smooth to='/lottery'>Lotteries</HashLink>
                                </li>
                                <li className="nav-item">
                                    <HashLink className='nav-link' smooth to='/#app'>Coming Soon</HashLink>
                                </li>
                                {/* <li className="nav-item">
                                    <HashLink className='nav-link' smooth to='/#'>Idea</HashLink>
                                </li> */}

                            </ul>
                            <div className=" ml-auto button-head">
                                {address == null || address == ''
                                    ? <button onClick={this.connectWallet}>
                                        {!isLogin
                                            ? 'Connect'
                                            : <div className='login-loader'><i className='fa fa-spinner fa-spin fa-1x fa-fw' /></div>
                                        }
                                    </button>
                                    : <button onClick={this.toggleLogoutModal}>
                                        {address && address.substring(0, 9) + '.....'}
                                    </button>
                                }
                            </div>
                            <div className="button-heads">
                                <Link to='/createprofle'> Create Profile </Link>
                            </div>

                        </div>
                    </div>
                </nav>

            </div>
        );
    }
}

const mapDispatchToProps = { login, isLoggedIn, logout, signup, toggleConnectModal, toggleSwitchModal };

const mapStateToProps = ({ Auth }) => {
    let { auth, isLogin, address, isPolicyModal } = Auth;
    return { auth, isLogin, address, isPolicyModal }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);