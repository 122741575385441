import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import './index.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    render() {
        return (
            <footer className='footer-sec'>        
                    <div class="container ">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="footer-widget logo-widget">
                                    <div className="footer-logo">
                                        <img src={require("../../static/images/landing/footer-logo.png")} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="footer-widget link-widget">
                                    <h3 className="">Company</h3>
                                    <ul>
                                        <li><HashLink smooth to='/#feature'>Features</HashLink></li>
                                        <li><HashLink smooth to='/lottery'>Lotteries</HashLink></li>
                                        <li><HashLink smooth to='/#app'>Comin Soon</HashLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="footer-widget link-widget">
                                    <h3 className="">Services</h3>
                                    <ul>
                                        <li><HashLink smooth to='/createprofle'>Create Profile</HashLink></li>
                                        <li><HashLink smooth to='/editprofle'>Edit Profile</HashLink></li>
                                        <li><HashLink smooth to='/#app'>Comin Soon</HashLink></li>
                                    </ul>
                                </div>
                            </div>
                            
                        </div>

                        
                       
                    </div>
                <div className="footer-bottom">
                    <div class="container ">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="social-icon">
                                    <ul>
                                        <li>
                                            <a href="#"><i className="fa fa-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-twitter"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="copy-right-footer">
                                    <p>All Right Reserved 2021</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="lang-footer">
                                    <p>Language: English(UK)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;