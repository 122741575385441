import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import './index.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer'; 


class Lottery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: true,
            lotteryModal: false
        };
    }

    componentDidMount() {
        import('wowjs').then((WOW) => new WOW.WOW({
          boxClass: 'wow',           // animated element css class (default is wow)
          animateClass: 'animated',  // animation css class (default is animated)
          offset: 5,                 // distance to the element when triggering the animation (default is 0)
          mobile: true,              // trigger animations on mobile devices (default is true)
          live: true,                // act on asynchronously loaded content (default is true)
          callback: function (box) {
            // the callback is fired every time an animation is started
            // the argument that is passed in is the DOM node being animated
          },
          scrollContainer: null      // optional scroll container selector, otherwise use window
        }).init());
      };

      toggleLotteryModal = () => this.setState({ lotteryModal: !this.state.lotteryModal })

    render() {
        let { lotteryModal, isChecked } = this.state;
        return (
            <div className="lottery-page">
                <div className="lottery-bg" style={{ backgroundImage: `url(${require("../../static/images/landing/lottries/editporfile.png")})` }}>
                <Navbar />
                    <section className="lottery-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="sec-title text-center">
                                        <h2>Lotteries</h2>
                                        <p>The list includes both major and smaller-scale lotteries.</p>
                                    </div>
                                    <div className="filter-sec">
                                        <h3>Filter Tickets by:</h3>
                                        <div className="filters">
                                            <ul className="filters-nav">
                                                <li className="dropdown"><a href="#">Price <i className="icon fa fa-sort-down"></i></a>
                                                    <ul className="submenu">
                                                        <li><a href="#">10,000 to 50,000</a></li>
                                                        <li><a href="#">50,000 to 100,000</a></li>
                                                        <li><a href="#">100,000 to 500,000</a></li>
                                                        <li><a href="#">500,000 to 1000,000</a></li>
                                                        <li><a href="#">1000,000 and up</a></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown"><a href="#">Max entries <i className="icon fa fa-sort-down"></i></a>
                                                    <ul className="submenu">
                                                        <li><a href="#">10,000 to 50,000</a></li>
                                                        <li><a href="#">50,000 to 100,000</a></li>
                                                        <li><a href="#">100,000 to 500,000</a></li>
                                                        <li><a href="#">500,000 to 1000,000</a></li>
                                                        <li><a href="#">1000,000 and up</a></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown"><a href="#">Start date <i className="icon fa fa-sort-down"></i></a>
                                                    <ul className="submenu">
                                                        <li><a href="#">01 to 10</a></li>
                                                        <li><a href="#">10 to 20</a></li>
                                                        <li><a href="#">20 to 30</a></li>
                                                    </ul>
                                                </li>

                                                <li className="dropdown"><a href="#">End date <i className="icon fa fa-sort-down"></i></a>
                                                    <ul className="submenu">
                                                        <li><a href="#">01 to 10</a></li>
                                                        <li><a href="#">10 to 20</a></li>
                                                        <li><a href="#">20 to 30</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        <a href="#" class="btn-style-one">Apply filter</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Price Section */}
                    <div className="price-sec">
                        <div className="container">
                            <div className="row">
                            {/* Price block */}
                            <div className="price-block col-lg-4 col-md-6 col-sm-12">
                                <div className="price-inner text-center wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="100ms">
                                <div className="price-tag">
                                    <h4>3</h4>
                                    <p>Days left</p>
                                </div>
                                <div className="text-block">
                                    <h3>Powerball!</h3>
                                    <p>Start date: 7 August 2021</p>
                                    <h4 className="price">$10,000</h4>
                                    <ul className="detail-area">
                                    <li>End date: 01/09/21</li>
                                    <li>Total Tickets: 346</li>
                                    <li>Reserved Tickets: 300</li>
                                    </ul>
                                    <button className="btn-style-two" onClick={this.toggleLotteryModal}>See more</button>
                                </div>
                                </div>
                            </div>
                            {/* Price block */}
                            <div className="price-block price-block-active col-lg-4 col-md-6 col-sm-12">
                                <div className="price-inner text-center wow fadeInLeft" data-wow-delay="400ms" data-wow-duration="400ms">
                                <div className="price-tag">
                                    <h4>5</h4>
                                    <p>Days left</p>
                                </div>
                                <div className="text-block">
                                    <h3>Powerball!</h3>
                                    <p>Start date: 7 August 2021</p>
                                    <h4 className="price">$10,000</h4>
                                    <ul className="detail-area">
                                    <li>End date: 01/09/21</li>
                                    <li>Total Tickets: 346</li>
                                    <li>Reserved Tickets: 300</li>
                                    </ul>
                                    <button href="#" className="btn-style-two" onClick={this.toggleLotteryModal}>See more</button>
                                </div>
                                </div>
                            </div>
                            {/* Price block */}
                            <div className="price-block col-lg-4 col-md-6 col-sm-12">
                                <div className="price-inner text-center wow fadeInLeft" data-wow-delay="700ms" data-wow-duration="700ms">
                                <div className="price-tag">
                                    <h4>9</h4>
                                    <p>Days left</p>
                                </div>
                                <div className="text-block">
                                    <h3>Powerball!</h3>
                                    <p>Start date: 7 August 2021</p>
                                    <h4 className="price">$10,000</h4>
                                    <ul className="detail-area">
                                    <li>End date: 01/09/21</li>
                                    <li>Total Tickets: 346</li>
                                    <li>Reserved Tickets: 300</li>
                                    </ul>
                                    <button href="#" className="btn-style-two" onClick={this.toggleLotteryModal}>See more</button>
                                </div>
                                </div>
                            </div>


                            {/* Price block */}
                            <div className="price-block col-lg-4 col-md-6 col-sm-12">
                                <div className="price-inner text-center wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="100ms">
                                <div className="price-tag">
                                    <h4>3</h4>
                                    <p>Days left</p>
                                </div>
                                <div className="text-block">
                                    <h3>Powerball!</h3>
                                    <p>Start date: 7 August 2021</p>
                                    <h4 className="price">$10,000</h4>
                                    <ul className="detail-area">
                                    <li>End date: 01/09/21</li>
                                    <li>Total Tickets: 346</li>
                                    <li>Reserved Tickets: 300</li>
                                    </ul>
                                    <button className="btn-style-two" onClick={this.toggleLotteryModal}>See more</button>
                                </div>
                                </div>
                            </div>
                            {/* Price block */}
                            <div className="price-block price-block-active col-lg-4 col-md-6 col-sm-12">
                                <div className="price-inner text-center wow fadeInLeft" data-wow-delay="400ms" data-wow-duration="400ms">
                                <div className="price-tag">
                                    <h4>5</h4>
                                    <p>Days left</p>
                                </div>
                                <div className="text-block">
                                    <h3>Powerball!</h3>
                                    <p>Start date: 7 August 2021</p>
                                    <h4 className="price">$10,000</h4>
                                    <ul className="detail-area">
                                    <li>End date: 01/09/21</li>
                                    <li>Total Tickets: 346</li>
                                    <li>Reserved Tickets: 300</li>
                                    </ul>
                                    <button href="#" className="btn-style-two" onClick={this.toggleLotteryModal}>See more</button>
                                </div>
                                </div>
                            </div>
                            {/* Price block */}
                            <div className="price-block col-lg-4 col-md-6 col-sm-12">
                                <div className="price-inner text-center wow fadeInLeft" data-wow-delay="700ms" data-wow-duration="700ms">
                                <div className="price-tag">
                                    <h4>9</h4>
                                    <p>Days left</p>
                                </div>
                                <div className="text-block">
                                    <h3>Powerball!</h3>
                                    <p>Start date: 7 August 2021</p>
                                    <h4 className="price">$10,000</h4>
                                    <ul className="detail-area">
                                    <li>End date: 01/09/21</li>
                                    <li>Total Tickets: 346</li>
                                    <li>Reserved Tickets: 300</li>
                                    </ul>
                                    <button href="#" className="btn-style-two" onClick={this.toggleLotteryModal}>See more</button>
                                </div>
                                </div>
                            </div>

                            {/* Price block */}
                            <div className="price-block col-lg-4 col-md-6 col-sm-12">
                                <div className="price-inner text-center wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="100ms">
                                <div className="price-tag">
                                    <h4>3</h4>
                                    <p>Days left</p>
                                </div>
                                <div className="text-block">
                                    <h3>Powerball!</h3>
                                    <p>Start date: 7 August 2021</p>
                                    <h4 className="price">$10,000</h4>
                                    <ul className="detail-area">
                                    <li>End date: 01/09/21</li>
                                    <li>Total Tickets: 346</li>
                                    <li>Reserved Tickets: 300</li>
                                    </ul>
                                    <button className="btn-style-two" onClick={this.toggleLotteryModal}>See more</button>
                                </div>
                                </div>
                            </div>
                            {/* Price block */}
                            <div className="price-block price-block-active col-lg-4 col-md-6 col-sm-12">
                                <div className="price-inner text-center wow fadeInLeft" data-wow-delay="400ms" data-wow-duration="400ms">
                                <div className="price-tag">
                                    <h4>5</h4>
                                    <p>Days left</p>
                                </div>
                                <div className="text-block">
                                    <h3>Powerball!</h3>
                                    <p>Start date: 7 August 2021</p>
                                    <h4 className="price">$10,000</h4>
                                    <ul className="detail-area">
                                    <li>End date: 01/09/21</li>
                                    <li>Total Tickets: 346</li>
                                    <li>Reserved Tickets: 300</li>
                                    </ul>
                                    <button href="#" className="btn-style-two" onClick={this.toggleLotteryModal}>See more</button>
                                </div>
                                </div>
                            </div>
                            {/* Price block */}
                            <div className="price-block col-lg-4 col-md-6 col-sm-12">
                                <div className="price-inner text-center wow fadeInLeft" data-wow-delay="700ms" data-wow-duration="700ms">
                                <div className="price-tag">
                                    <h4>9</h4>
                                    <p>Days left</p>
                                </div>
                                <div className="text-block">
                                    <h3>Powerball!</h3>
                                    <p>Start date: 7 August 2021</p>
                                    <h4 className="price">$10,000</h4>
                                    <ul className="detail-area">
                                    <li>End date: 01/09/21</li>
                                    <li>Total Tickets: 346</li>
                                    <li>Reserved Tickets: 300</li>
                                    </ul>
                                    <button href="#" className="btn-style-two" onClick={this.toggleLotteryModal}>See more</button>
                                </div>
                                </div>
                            </div>

                            </div>
                            <div className="loadmore text-center">
                            <button href="#" className="btn-style-one">Explore more</button>
                            </div>
                        </div>
                    </div>
                    </section>

                    
                <Footer />

                
                </div>


                {/* ------------------LOTTERY MODAL----------------- */}

                <Modal isOpen={lotteryModal} toggle={this.toggleLotteryModal} className="ticket-popup">
                <ModalBody className="modal-body">
                    <ModalHeader toggle={this.toggleLotteryModal}></ModalHeader>
                    <div className="container modal-upper-pad">
                    <div className="form-modal-landing-nft">
                        <div className="left-content-area">
                            <h2>Powerball!</h2>
                            <h5>Start date: 7 August 2021</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vehicula fermentum interdum. Sed eu libero nec ligula vehicula lobortis ac ut nunc. Aenean varius nisl nec lacus suscipit aliquam.</p>
                        <a className="btn-style-two" href="#">See more</a>
                        </div>
                        <div className="right-content-area">
                        <div className="content-box">
                            <h4>6</h4>
                            <p>days left</p>
                        </div>
                        <div className="content-box">
                            <h4>$10,000</h4>
                            <p>UWT per ticket</p>
                        </div>
                        <div className="content-box">
                            <h4>256</h4>
                            <p>Ticket reserved</p>
                        </div>
                        <div className="content-box">
                            <h4>256</h4>
                            <p>Ticket left</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = {

};

const mapStateToProps = ({ }) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Lottery);