import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from 'react';

// import OurTeam from './ourTeam';

import './index.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";


class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lotteryModal: false,
      isChecked: true,
    };
  };

  componentDidMount() {
    import('wowjs').then((WOW) => new WOW.WOW({
      boxClass: 'wow',           // animated element css class (default is wow)
      animateClass: 'animated',  // animation css class (default is animated)
      offset: 5,                 // distance to the element when triggering the animation (default is 0)
      mobile: true,              // trigger animations on mobile devices (default is true)
      live: true,                // act on asynchronously loaded content (default is true)
      callback: function (box) {
        // the callback is fired every time an animation is started
        // the argument that is passed in is the DOM node being animated
      },
      scrollContainer: null      // optional scroll container selector, otherwise use window
    }).init());
  };

  toggleLotteryModal = () => this.setState({ lotteryModal: !this.state.lotteryModal })

  render() {
    let { lotteryModal, isChecked } = this.state;
    return (
      <div className='landing-red'>
        <Navbar />
        {/* Banner Section */}
        <section class="banner-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/lottries/banner-bg.jpg")})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="content-area">
                  <h1>Lottery Platform <span> with Blockchain </span></h1>
                  <p>We use blockchain to provide highly transparent digital services & competitive lottery platform. Our platform uses blockchain where it improves delivery or enhances the user experience. </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="image-area wow fadeInRight" data-wow-delay="100ms" data-wow-duration="100ms">
                  <img src={require("../../static/images/landing/headerbackimage.png")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* feature Section */}
        <section className="feature-sec" id="feature" style={{ backgroundImage: `url(${require("../../static/images/landing/lottries/feature-bg.png")})` }}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sec-title text-center">
                  <h2>Features</h2>
                  <p>Take a look at the features we provide</p>
                </div>
              </div>
              {/* feature Block */}
              <div className="feature-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="100ms" data-wow-duration="100ms">
                <div className="feature-inner text-center">
                  <div className="icon-area">
                    <img src={require("../../static/images/landing/icon-1.png")} alt="" />
                  </div>
                  <div className="text-block">
                    <h3>Improve fairness with smart contracts </h3>
                    <p>Smart contracts enhance the performance of our platform by giving our users the ability to track their transactions and also build trust. Smart contracts gave network automation and the ability to convert paper contracts into digital contracts. </p>
                  </div>
                </div>
              </div>
              {/* feature Block */}
              <div className="feature-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="400ms">
                <div className="feature-inner text-center">
                  <div className="icon-area">
                    <img src={require("../../static/images/landing/icon-2.png")} alt="" />
                  </div>
                  <div className="text-block">
                    <h3>Privacy and Accessibility </h3>
                    <p>Privacy of our users is our highest priority. Blockchain helps our users to stay anonymous and play poker or lottery without giving out their personal information. All users need is to have UWT tokens in their wallets to participate in the lottery. </p>
                  </div>
                </div>
              </div>
              {/* feature Block */}
              <div className="feature-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="700ms" data-wow-duration="700ms">
                <div className="feature-inner text-center">
                  <div className="icon-area">
                    <img src={require("../../static/images/landing/icon-3.png")} alt="" />
                  </div>
                  <div className="text-block">
                    <h3>Traceability </h3>
                    <p>Tracing your transaction is important and it should have the transparency to build trust and develop good will with our clients. For that matter all transactions will be traceable either its your transaction or your lottery winning for charity everything will be traceable.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>

        {/* Price Section */}
        <section className="price-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/lottries/price-bg.png")})` }}>
          <div className="price-bg" style={{ backgroundImage: `url(${require("../../static/images/landing/lottries/price-before.png")})` }}></div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sec-title text-center">
                  <h2>LOTTERY JACKPOTS</h2>
                </div>
              </div>
              {/* Price block */}
              <div className="price-block col-lg-4 col-md-6 col-sm-12" style={{ backgroundImage: `url(${require("../../static/images/landing/price-table-block.png")})` }}>
                <div className="price-inner price-inner-1 text-center wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="100ms">
                  <div className="price-tag">
                    <h4>3</h4>
                    <p>Days left</p>
                  </div>
                  <div className="text-block">
                    <h3>Powerball!</h3>
                    <p>Start date: 15/08/21</p>
                    <h4 className="price">$10,000</h4>
                    <ul className="detail-area">
                      <li>End date: 01/09/21</li>
                      <li>Total Tickets: 346</li>
                      <li>Reserved Tickets: 300</li>
                    </ul>
                    <button className="btn-style-two" onClick={this.toggleLotteryModal}>See more</button>
                  </div>
                </div>
              </div>
              {/* Price block */}
              <div className="price-block price-block-active col-lg-4 col-md-6 col-sm-12" style={{ backgroundImage: `url(${require("../../static/images/landing/price-table-block.png")})` }}>
                <div className="price-inner price-inner-2 text-center wow fadeInLeft" data-wow-delay="400ms" data-wow-duration="400ms">
                  <div className="price-tag">
                    <h4>5</h4>
                    <p>Days left</p>
                  </div>
                  <div className="text-block">
                    <h3>Powerball!</h3>
                    <p>Start date: 15/08/21</p>
                    <h4 className="price">$10,000</h4>
                    <ul className="detail-area">
                      <li>End date: 01/09/21</li>
                      <li>Total Tickets: 346</li>
                      <li>Reserved Tickets: 300</li>
                    </ul>
                    <button href="#" className="btn-style-two" onClick={this.toggleLotteryModal}>See more</button>
                  </div>
                </div>
              </div>
              {/* Price block */}
              <div className="price-block col-lg-4 col-md-6 col-sm-12" style={{ backgroundImage: `url(${require("../../static/images/landing/price-table-block.png")})` }}>
                <div className="price-inner price-inner-1 text-center wow fadeInLeft" data-wow-delay="700ms" data-wow-duration="700ms">
                  <div className="price-tag">
                    <h4>9</h4>
                    <p>Days left</p>
                  </div>
                  <div className="text-block">
                    <h3>Powerball!</h3>
                    <p>Start date: 15/08/21</p>
                    <h4 className="price">$10,000</h4>
                    <ul className="detail-area">
                      <li>End date: 01/09/21</li>
                      <li>Total Tickets: 346</li>
                      <li>Reserved Tickets: 300</li>
                    </ul>
                    <button href="#" className="btn-style-two" onClick={this.toggleLotteryModal}>See more</button>
                  </div>
                </div>
              </div>

            </div>
            <div className="loadmore text-center">
              <button href="#" className="btn-style-one">Explore more</button>
            </div>
          </div>
        </section>

        {/* lottery Section */}
        <section className="lottery-sec  wow fadeInDwon" data-wow-delay="400ms" data-wow-duration="400ms" id="app" style={{ backgroundImage: `url(${require("../../static/images/landing/lottries/lottery-bg.png")})` }}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sec-title text-center">
                  <h2>Casino </h2>
                  <h3>Coming soon</h3>
                  <p>A full casino platform is coming very soon where you will have a virtual experience of a Casino with Holdem Poker game where chips will be UWT tokens, <br /> Black jack will in our roadmap.</p>
                </div>
                <div className="lottery-image">
                  <img src={require("../../static/images/landing/lottery.png")} alt="" />
                  <h4>Coming Soon</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* faq Section */}
        <section className="faq-sec">
          <div className="container">
            <div className="row">
              <div className="faq-block col-lg-6 col-md-12 col-sm-12  wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="200ms">
                <div className="sec-title text-center">
                  <h2>FAQ's</h2>
                </div>
                <div id="accordion">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        What is the United world lottery platform?
                              </button>
                      </h5>
                    </div>
                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                      <div class="card-body">
                      The United World Lottery is a lottery platform which gives charities of all shapes and sizes.</div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h5 class="mb-0">
                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        How do lotteries work?
                              </button>
                      </h5>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                      <div class="card-body">
                      In the United world lottery platform lotteries work in a unique fashion: a random player wins the lottery and gets 50% of the winning of the pool while the rest goes to charitable organizations.
                            </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Where does the money go?
                              </button>
                      </h5>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                      <div class="card-body">
                      For every $1 played, at least 50% goes directly to the respective cause as profit. The other 50% is split between the prize pot and administration costs.
                            </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingFour">
                      <h5 class="mb-0">
                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Can you guarantee lottery is fair?
                              </button>
                      </h5>
                    </div>
                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                      <div class="card-body">
                      Yes, every entry has an equal chance of winning, and the winning numbers are drawn at random.
                            </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="form-block col-lg-5 offset-lg-1 col-md-12 col-sm-12  wow fadeInRight" data-wow-delay="400ms" data-wow-duration="400ms">
                <div className="sec-title text-center">
                  <h2>Contact Us</h2>
                </div>
                <div className="form-contact">
                  <form className="form">
                    <div className="group-form">
                      <input type="text" name="name" placeholder="Name" />
                    </div>
                    <div className="group-form">
                      <input type="email" name="email" placeholder="Email" />
                    </div>
                    <div className="group-form">
                      <textarea placeholder="Message"></textarea>
                    </div>
                    <div className="group-form text-center">
                      <button type="button" className="btn-style-one">Send</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />

        {/* ------------------LOTTERY MODAL----------------- */}

        <Modal isOpen={lotteryModal} toggle={this.toggleLotteryModal} className="lottery-popup">
          <ModalBody className="modal-body">
            <ModalHeader toggle={this.toggleLotteryModal}></ModalHeader>
            <div className="container modal-upper-pad">
              <div className="form-modal-landing-nft">
                <div className="left-content-area">
                  <h2>LOTTERY</h2>
                  <div className="explore-area">
                    <h5>ROUND 009</h5>
                    <fieldset>
                      {/* <div class="toggle">
                        <input type="radio" name="sizeBy" value="weight" id="sizeWeight" checked="checked" />
                        <label className={isChecked && 'active'} onClick={() => this.setState({ isChecked: true })} for="sizeWeight">Contract</label>
                        <input type="radio" name="sizeBy" value="dimensions" id="sizeDimensions" />
                        <label className={!isChecked && 'active'} onClick={() => this.setState({ isChecked: false })} for="sizeDimensions">Explorer</label>
                      </div> */}
                      <div className='toggle'>
                        <button className={`contract-btn ${isChecked && 'active'}`} onClick={() => this.setState({ isChecked: true })} type="button">Contract</button>
                        <button className={`explorer-btn ${!isChecked && 'active'}`} onClick={() => this.setState({ isChecked: false })} type="button">Explorer</button>
                      </div>
                    </fieldset>
                  </div>
                  <div className="count-area">
                    <a href="#" className="icon-plus"><i className="fa fa-minus"></i></a>
                    <input type="text" name="ticketnumber" placeholder="Number of Tickets" />
                    <a href="#" className="icon-plus"><i className="fa fa-plus"></i></a>
                  </div>
                  <button className="btn-style-one">Buy</button>
                </div>
                <div className="right-content-area">
                  <div className="content-box">
                    <h4>6</h4>
                    <p>days left</p>
                  </div>
                  <div className="content-box">
                    <h4>$10,000</h4>
                    <p>UWT per ticket</p>
                  </div>
                  <div className="content-box">
                    <h4>256</h4>
                    <p>Ticket reserved</p>
                  </div>
                  <div className="content-box">
                    <h4>256</h4>
                    <p>Ticket left</p>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>


        {/* <Modal isOpen={false} toggle={this.props.toggleBuyWallet} className="register-modal buy-token-modal">
          <div class="icon-area">
            <h1>6 <br></br><span>Days Left</span></h1>
          </div>
          <ModalHeader toggle={this.props.toggleBuyWallet}>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="modal-body">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 col-md-12 col-12 offset-md-0 offset-lg-1">
                  <div className="text-head-lott text-center">
                    <h5>LOTTERY</h5>
                  </div>
                  <div className="main-text-head-lott ">
                    <div className="text-time">
                      <h6>Time Left:<br></br> <span>6 days</span></h6>
                    </div>
                    <div className="text-time first-2ndd">
                      <h6>Ticket price in UWT: <br></br> <span>$10,000</span></h6>
                    </div>
                    <div className="text-time">
                      <h6>Ticket Reserved: <br></br> <span>256</span></h6>
                    </div>
                    <div className="text-time first-2nd">
                      <h6>Tickets Left:<br></br> <span>256</span></h6>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="main-divs-lotty">
              <div className="main-image-lottry">

              </div>
              <div className="main-button-increase">
                <div className="inner-div-lottery ">
                  <p>Round 009</p>
                  <div className="button-modal-buy-lottry">
                    <button className="button-one" type="button">CONTRACT</button>
                    <button className="button-two" type="button">EXPLORE</button>
                  </div>
                </div>

                <div className="input-fild-lottry">
                  <input className="input-modal-ticke-number" type="text" id="fname" name="fname" placeholder="TICKET NUMBER" />
                  <div className="increa-decre-button">
                    <button type="button"><i class="fa fa-plus" aria-hidden="true"></i></button>
                    <input className="input-increase" type="text" value="256" id="fname" name="fname" />
                    <button type="button"><i class="fa fa-minus" aria-hidden="true"></i></button>
                  </div>
                </div>

                <div className="buy-button">
                  <button className="buy-button-buy">BUY</button>
                </div>
              </div>
            </div>

          </ModalBody>
        </Modal> */}

      </div>
    );
  }

}


const mapDispatchToProps = {
};

const mapStateToProps = ({ }) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);